﻿//背景用色
$color-main:#01916d; //主题绿色
$color-main-dark:#026d52;
$color-main-light:#f2f4f8; 
$color-main-bg:#eee;

//border用色
$color-line:#b2b2b2;

//文字用色
$color-red:#ff0909; //错误提示文字颜色
$color-title:#7b7b7b; //标题黑色
$color-text:#585858; //正文颜色  80%黑
$color-desc:#999999; //解释性文字颜色  60%黑


// 动画效果持续时间
$anime-duration: 0.4s;
//贝塞尔曲线
$anime-bezier:cubic-bezier(0.77, 0, 0.175, 1);


//内容部分左右留白
$container-gutter-base:80px;
$container-gutter-ratio:10 / 80;


//汉堡包按钮参数设置
$hamburger-layer-width: 28px; //汉堡包按钮每一条线的宽度
$hamburger-layer-height: 3px; //汉堡包按钮每一条线的高度
$hamburger-layer-spacing: 5px; //汉堡包按钮每一条线的高度间隔
$hamburger-layer-color: $color-main; //汉堡包按钮线的颜色
$hamburger-layer-border-radius: 0; //汉堡包按钮线的圆角效果

// 响应式断点，需要从小到大写
$layout-responsive-breakpoint: ( // 手机
  xs: (min-width: 0px, container-width: 100%),
  // 平板
  sm: (min-width: 768px, container-width: 720px),
  // 小屏
  md: (min-width: 992px, container-width: 960px),
  // 中屏
  mmd: (min-width: 1025px, container-width: 1000px),
  // 大屏
  lg: (min-width: 1367px, container-width: 1230px),

  llg: (min-width: 1601px, container-width: 1230px)) !default;
// 汉堡包
.hamburger {
  display: block;
  cursor: pointer;
  background-color: transparent;
  overflow: visible;
  position: relative;
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  > div, > view {
    display: block;
    top: 50%;
    margin-top: $hamburger-layer-height / -2;
    &,
    &::before,
    &::after {
      width: $hamburger-layer-width;
      height: $hamburger-layer-height;
      background-color: #61ae8f;
      border-radius: $hamburger-layer-border-radius;
      position: absolute;
      transition-property: all;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }
    &::before,
    &::after {
      content: "";
      display: block;
    }
    &::before {
      top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }
    &::after {
      bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }
  }

  &.hamburger-11 {
    > div, > view {
      margin-top: -2px;
      transition-duration: .1s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      &::before {
        transition: top 0.1s 0.14s ease, opacity 0.1s ease, background-color 0.3s linear;
      }
      &::after {
        transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
      }
    }
    &.active {
      > div, > view {
        transform: rotate(45deg);
        transition-delay: 0.14s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        &::before {
          top: 0;
          opacity: 0;
          transition: top .1s ease, opacity 0.1s 0.14s ease, background-color 0.3s linear;
        }
        &::after {
          bottom: 0;
          transform: rotate(-90deg);
          transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
        }
      }
    }
  }

}