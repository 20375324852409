.hamburger {
  display: block;
  cursor: pointer;
  background-color: transparent;
  overflow: visible;
  position: relative;
  width: 28px;
  height: 19px;
}

.hamburger > div, .hamburger > view {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}

.hamburger > div, .hamburger > div::before, .hamburger > div::after, .hamburger > view, .hamburger > view::before, .hamburger > view::after {
  width: 28px;
  height: 3px;
  background-color: #61ae8f;
  border-radius: 0;
  position: absolute;
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger > div::before, .hamburger > div::after, .hamburger > view::before, .hamburger > view::after {
  content: "";
  display: block;
}

.hamburger > div::before, .hamburger > view::before {
  top: -8px;
}

.hamburger > div::after, .hamburger > view::after {
  bottom: -8px;
}

.hamburger.hamburger-11 > div, .hamburger.hamburger-11 > view {
  margin-top: -2px;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-11 > div::before, .hamburger.hamburger-11 > view::before {
  transition: top 0.1s 0.14s ease, opacity 0.1s ease, background-color 0.3s linear;
}

.hamburger.hamburger-11 > div::after, .hamburger.hamburger-11 > view::after {
  transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-11.active > div, .hamburger.hamburger-11.active > view {
  transform: rotate(45deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-11.active > div::before, .hamburger.hamburger-11.active > view::before {
  top: 0;
  opacity: 0;
  transition: top .1s ease, opacity 0.1s 0.14s ease, background-color 0.3s linear;
}

.hamburger.hamburger-11.active > div::after, .hamburger.hamburger-11.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.index-banner {
  height: 800px;
  margin-top: 122px;
}

@media (max-width: 1600px) {
  .index-banner {
    height: 710px;
  }
}

@media (max-width: 1366px) {
  .index-banner {
    height: 620px;
  }
}

@media (max-width: 1024px) {
  .index-banner {
    height: 530px;
  }
}

@media (max-width: 991px) {
  .index-banner {
    height: 440px;
  }
}

@media (max-width: 767px) {
  .index-banner {
    height: 350px;
  }
}

@media (max-width: 991px) {
  .index-banner {
    margin-top: 60px;
  }
}

.index-banner .swiper {
  height: 100%;
  position: relative;
}

.index-banner .swiper .swiper-slide {
  position: relative;
  width: 100%;
  height: 100%;
}

.index-banner .swiper .swiper-slide .title {
  position: absolute;
  color: #fff;
  top: 32%;
  font-family: OPPOSans-H;
  left: 0;
  right: 0;
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .title {
    top: 40px;
  }
}

.index-banner .swiper .swiper-slide .title h2 {
  font-size: 63px;
  line-height: 82px;
  font-weight: bold;
  margin-bottom: 28px;
  letter-spacing: 1px;
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-slide .title h2 {
    font-size: 58.8px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .title h2 {
    font-size: 54.6px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .title h2 {
    font-size: 50.4px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .title h2 {
    font-size: 46.2px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .title h2 {
    font-size: 42px;
  }
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-slide .title h2 {
    line-height: 76.53333px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .title h2 {
    line-height: 71.06667px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .title h2 {
    line-height: 65.6px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .title h2 {
    line-height: 60.13333px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .title h2 {
    line-height: 54.66667px;
  }
}

.index-banner .swiper .swiper-slide .title p {
  font-family: OPPOSans-L1;
  font-size: 27px;
  line-height: 34px;
  letter-spacing: 1px;
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-slide .title p {
    font-size: 25.2px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .title p {
    font-size: 23.4px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .title p {
    font-size: 21.6px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .title p {
    font-size: 19.8px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .title p {
    font-size: 18px;
  }
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-slide .title p {
    line-height: 32.3px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-slide .title p {
    line-height: 30.6px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .title p {
    line-height: 28.9px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-slide .title p {
    line-height: 27.2px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-slide .title p {
    line-height: 25.5px;
  }
}

.index-banner .swiper .swiper-control {
  bottom: 0.73rem;
  left: 0;
  right: 0;
  position: absolute;
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-control {
    bottom: 40px;
  }
}

.index-banner .swiper .swiper-control .swiper-page {
  width: max-content;
  position: relative;
  bottom: 0;
  left: 0;
  right: auto;
  display: flex;
  align-items: center;
  z-index: 1;
}

.index-banner .swiper .swiper-control .swiper-page .swiper-pagination-bullet {
  width: 9px;
  height: 9px;
  background-color: transparent;
  border: 1px solid #fff;
  opacity: 1;
  margin: 0;
  margin-right: 47px;
  position: relative;
}

.index-banner .swiper .swiper-control .swiper-page .swiper-pagination-bullet:last-child {
  margin-right: 0;
}

.index-banner .swiper .swiper-control .swiper-page .swiper-pagination-bullet-active {
  width: 12px;
  height: 12px;
  background: #fff;
}

.index-banner .swiper .swiper-control .line {
  width: 100%;
  border-bottom: 1px solid #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.index .section1 {
  margin-top: 85px;
  padding-bottom: 148px;
}

@media (max-width: 1600px) {
  .index .section1 {
    margin-top: 74px;
  }
}

@media (max-width: 1366px) {
  .index .section1 {
    margin-top: 63px;
  }
}

@media (max-width: 1024px) {
  .index .section1 {
    margin-top: 52px;
  }
}

@media (max-width: 991px) {
  .index .section1 {
    margin-top: 41px;
  }
}

@media (max-width: 767px) {
  .index .section1 {
    margin-top: 30px;
  }
}

@media (max-width: 1600px) {
  .index .section1 {
    padding-bottom: 124.4px;
  }
}

@media (max-width: 1366px) {
  .index .section1 {
    padding-bottom: 100.8px;
  }
}

@media (max-width: 1024px) {
  .index .section1 {
    padding-bottom: 77.2px;
  }
}

@media (max-width: 991px) {
  .index .section1 {
    padding-bottom: 53.6px;
  }
}

@media (max-width: 767px) {
  .index .section1 {
    padding-bottom: 30px;
  }
}

.index .section1 .content {
  margin-top: 66px;
}

@media (max-width: 1600px) {
  .index .section1 .content {
    margin-top: 57.8px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .content {
    margin-top: 49.6px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .content {
    margin-top: 41.4px;
  }
}

@media (max-width: 991px) {
  .index .section1 .content {
    margin-top: 33.2px;
  }
}

@media (max-width: 767px) {
  .index .section1 .content {
    margin-top: 25px;
  }
}

.index .section1 .content .product-list {
  margin-bottom: 85px;
}

@media (max-width: 1600px) {
  .index .section1 .content .product-list {
    margin-bottom: 73px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .content .product-list {
    margin-bottom: 61px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .content .product-list {
    margin-bottom: 49px;
  }
}

@media (max-width: 991px) {
  .index .section1 .content .product-list {
    margin-bottom: 37px;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-list {
    margin-bottom: 25px;
  }
}

.index .section1 .content .product-list ul {
  display: none;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
}

.index .section1 .content .product-list ul.active {
  display: flex;
}

.index .section1 .content .product-list ul li {
  background-color: #f8f8f8;
  width: 32%;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 48px;
  padding-bottom: 54px;
  margin-bottom: 0;
  box-sizing: border-box;
  position: relative;
  transition: all .5s;
}

@media (max-width: 767px) {
  .index .section1 .content .product-list ul li {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .index .section1 .content .product-list ul li {
    padding-left: 42px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .content .product-list ul li {
    padding-left: 34px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .content .product-list ul li {
    padding-left: 26px;
  }
}

@media (max-width: 991px) {
  .index .section1 .content .product-list ul li {
    padding-left: 18px;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-list ul li {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index .section1 .content .product-list ul li {
    padding-right: 42px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .content .product-list ul li {
    padding-right: 34px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .content .product-list ul li {
    padding-right: 26px;
  }
}

@media (max-width: 991px) {
  .index .section1 .content .product-list ul li {
    padding-right: 18px;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-list ul li {
    padding-right: 10px;
  }
}

@media (max-width: 1600px) {
  .index .section1 .content .product-list ul li {
    padding-top: 40.32px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .content .product-list ul li {
    padding-top: 32.64px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .content .product-list ul li {
    padding-top: 24.96px;
  }
}

@media (max-width: 991px) {
  .index .section1 .content .product-list ul li {
    padding-top: 17.28px;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-list ul li {
    padding-top: 9.6px;
  }
}

@media (max-width: 1600px) {
  .index .section1 .content .product-list ul li {
    padding-bottom: 45.36px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .content .product-list ul li {
    padding-bottom: 36.72px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .content .product-list ul li {
    padding-bottom: 28.08px;
  }
}

@media (max-width: 991px) {
  .index .section1 .content .product-list ul li {
    padding-bottom: 19.44px;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-list ul li {
    padding-bottom: 10.8px;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-list ul li {
    margin-bottom: 15px;
  }
}

.index .section1 .content .product-list ul li.active {
  width: 760px;
  padding-left: 58px;
  padding-right: 58px;
}

@media (max-width: 1600px) {
  .index .section1 .content .product-list ul li.active {
    width: 48%;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-list ul li.active {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .index .section1 .content .product-list ul li.active {
    padding-left: 48.72px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .content .product-list ul li.active {
    padding-left: 39.44px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .content .product-list ul li.active {
    padding-left: 30.16px;
  }
}

@media (max-width: 991px) {
  .index .section1 .content .product-list ul li.active {
    padding-left: 20.88px;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-list ul li.active {
    padding-left: 11.6px;
  }
}

@media (max-width: 1600px) {
  .index .section1 .content .product-list ul li.active {
    padding-right: 48.72px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .content .product-list ul li.active {
    padding-right: 39.44px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .content .product-list ul li.active {
    padding-right: 30.16px;
  }
}

@media (max-width: 991px) {
  .index .section1 .content .product-list ul li.active {
    padding-right: 20.88px;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-list ul li.active {
    padding-right: 11.6px;
  }
}

.index .section1 .content .product-list ul li.active .more {
  display: flex;
}

.index .section1 .content .product-list ul li.active .text {
  display: flex;
}

.index .section1 .content .product-list ul li.active .text p {
  width: 245px;
}

@media (max-width: 767px) {
  .index .section1 .content .product-list ul li.active .text p {
    width: 100%;
  }
}

.index .section1 .content .product-list ul li:hover .title h2 {
  color: #61AE8F;
}

.index .section1 .content .product-list ul li:hover .title span {
  background: #61AE8F;
}

.index .section1 .content .product-list ul li .title h2 {
  font-family: OPPOSans-L;
  font-size: 28px;
  color: #7b7b7b;
  transition: all .3s;
}

@media (max-width: 1600px) {
  .index .section1 .content .product-list ul li .title h2 {
    font-size: 26.8px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .content .product-list ul li .title h2 {
    font-size: 25.6px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .content .product-list ul li .title h2 {
    font-size: 24.4px;
  }
}

@media (max-width: 991px) {
  .index .section1 .content .product-list ul li .title h2 {
    font-size: 23.2px;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-list ul li .title h2 {
    font-size: 22px;
  }
}

.index .section1 .content .product-list ul li .title span {
  display: block;
  width: 21px;
  height: 2px;
  background-color: #7b7b7b;
  margin-top: 14px;
  transition: all .3s;
}

@media (max-width: 1600px) {
  .index .section1 .content .product-list ul li .title span {
    margin-top: 13.2px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .content .product-list ul li .title span {
    margin-top: 12.4px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .content .product-list ul li .title span {
    margin-top: 11.6px;
  }
}

@media (max-width: 991px) {
  .index .section1 .content .product-list ul li .title span {
    margin-top: 10.8px;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-list ul li .title span {
    margin-top: 10px;
  }
}

.index .section1 .content .product-list ul li .text {
  display: block;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 30px;
}

@media (max-width: 767px) {
  .index .section1 .content .product-list ul li .text {
    flex-wrap: wrap;
  }
}

@media (max-width: 1600px) {
  .index .section1 .content .product-list ul li .text {
    margin-top: 27px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .content .product-list ul li .text {
    margin-top: 24px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .content .product-list ul li .text {
    margin-top: 21px;
  }
}

@media (max-width: 991px) {
  .index .section1 .content .product-list ul li .text {
    margin-top: 18px;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-list ul li .text {
    margin-top: 15px;
  }
}

.index .section1 .content .product-list ul li .text p {
  font-family: OPPOSans-L;
  font-size: 16px;
  line-height: 25px;
  color: #7B7B7B;
  width: 100%;
}

@media (max-width: 1600px) {
  .index .section1 .content .product-list ul li .text p {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .content .product-list ul li .text p {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .content .product-list ul li .text p {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .index .section1 .content .product-list ul li .text p {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-list ul li .text p {
    font-size: 14px;
  }
}

.index .section1 .content .product-list ul li .text .pic {
  margin-top: 30px;
  width: 100%;
  margin: auto;
  aspect-ratio: 394 / 372;
}

@media (max-width: 1600px) {
  .index .section1 .content .product-list ul li .text .pic {
    margin-top: 27px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .content .product-list ul li .text .pic {
    margin-top: 24px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .content .product-list ul li .text .pic {
    margin-top: 21px;
  }
}

@media (max-width: 991px) {
  .index .section1 .content .product-list ul li .text .pic {
    margin-top: 18px;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-list ul li .text .pic {
    margin-top: 15px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .content .product-list ul li .text .pic {
    width: 90%;
  }
}

@media (max-width: 1024px) {
  .index .section1 .content .product-list ul li .text .pic {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-list ul li .text .pic {
    width: 60%;
  }
}

.index .section1 .content .product-list ul li .more {
  position: absolute;
  bottom: 54px;
  left: 58px;
  display: none;
  align-items: center;
  justify-content: center;
  font-family: OPPOSans-L;
  font-size: 16px;
  color: #7B7B7B;
}

@media (max-width: 1600px) {
  .index .section1 .content .product-list ul li .more {
    bottom: 45.36px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .content .product-list ul li .more {
    bottom: 36.72px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .content .product-list ul li .more {
    bottom: 28.08px;
  }
}

@media (max-width: 991px) {
  .index .section1 .content .product-list ul li .more {
    bottom: 19.44px;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-list ul li .more {
    bottom: 10.8px;
  }
}

@media (max-width: 1600px) {
  .index .section1 .content .product-list ul li .more {
    left: 48.72px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .content .product-list ul li .more {
    left: 39.44px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .content .product-list ul li .more {
    left: 30.16px;
  }
}

@media (max-width: 991px) {
  .index .section1 .content .product-list ul li .more {
    left: 20.88px;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-list ul li .more {
    left: 11.6px;
  }
}

@media (max-width: 1600px) {
  .index .section1 .content .product-list ul li .more {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .content .product-list ul li .more {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .content .product-list ul li .more {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .index .section1 .content .product-list ul li .more {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-list ul li .more {
    font-size: 14px;
  }
}

.index .section1 .content .product-list ul li .more .pic {
  width: 58px;
  height: 58px;
  background-color: #61ae8f;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 15px;
}

@media (max-width: 1600px) {
  .index .section1 .content .product-list ul li .more .pic {
    width: 54.4px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .content .product-list ul li .more .pic {
    width: 50.8px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .content .product-list ul li .more .pic {
    width: 47.2px;
  }
}

@media (max-width: 991px) {
  .index .section1 .content .product-list ul li .more .pic {
    width: 43.6px;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-list ul li .more .pic {
    width: 40px;
  }
}

@media (max-width: 1600px) {
  .index .section1 .content .product-list ul li .more .pic {
    height: 54.4px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .content .product-list ul li .more .pic {
    height: 50.8px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .content .product-list ul li .more .pic {
    height: 47.2px;
  }
}

@media (max-width: 991px) {
  .index .section1 .content .product-list ul li .more .pic {
    height: 43.6px;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-list ul li .more .pic {
    height: 40px;
  }
}

.index .section1 .content .product-nav {
  overflow: hidden;
  overflow-x: auto;
  padding-bottom: 10px;
}

.index .section1 .content .product-nav::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.index .section1 .content .product-nav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0.2;
  background: #61AE8F;
}

.index .section1 .content .product-nav::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: fade(#fff, 30%);
}

.index .section1 .content .product-nav ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 47px;
  border-bottom: 2px solid #eeeeee;
}

@media (max-width: 1366px) {
  .index .section1 .content .product-nav ul {
    justify-content: flex-start;
  }
}

@media (max-width: 1600px) {
  .index .section1 .content .product-nav ul {
    padding-bottom: 41.6px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .content .product-nav ul {
    padding-bottom: 36.2px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .content .product-nav ul {
    padding-bottom: 30.8px;
  }
}

@media (max-width: 991px) {
  .index .section1 .content .product-nav ul {
    padding-bottom: 25.4px;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-nav ul {
    padding-bottom: 20px;
  }
}

.index .section1 .content .product-nav li {
  position: relative;
  white-space: nowrap;
  margin-right: 0;
}

@media (max-width: 1366px) {
  .index .section1 .content .product-nav li {
    margin-right: 20px;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-nav li {
    margin-right: 10px;
  }
}

.index .section1 .content .product-nav li a {
  display: flex;
  align-items: center;
}

.index .section1 .content .product-nav li a img {
  margin-right: 14px;
}

@media (max-width: 1600px) {
  .index .section1 .content .product-nav li a img {
    margin-right: 13.2px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .content .product-nav li a img {
    margin-right: 12.4px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .content .product-nav li a img {
    margin-right: 11.6px;
  }
}

@media (max-width: 991px) {
  .index .section1 .content .product-nav li a img {
    margin-right: 10.8px;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-nav li a img {
    margin-right: 10px;
  }
}

.index .section1 .content .product-nav li a p {
  font-family: OPPOSans-L;
  font-size: 22px;
  color: #7B7B7B;
  transition: all .3s;
}

@media (max-width: 1600px) {
  .index .section1 .content .product-nav li a p {
    font-size: 20.8px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .content .product-nav li a p {
    font-size: 19.6px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .content .product-nav li a p {
    font-size: 18.4px;
  }
}

@media (max-width: 991px) {
  .index .section1 .content .product-nav li a p {
    font-size: 17.2px;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-nav li a p {
    font-size: 16px;
  }
}

.index .section1 .content .product-nav li .line {
  position: absolute;
  width: 0;
  height: 3px;
  background-color: #61ae8f;
  bottom: -49px;
  left: 0;
  transition: all .3s;
}

@media (max-width: 1600px) {
  .index .section1 .content .product-nav li .line {
    bottom: -43.6px;
  }
}

@media (max-width: 1366px) {
  .index .section1 .content .product-nav li .line {
    bottom: -38.2px;
  }
}

@media (max-width: 1024px) {
  .index .section1 .content .product-nav li .line {
    bottom: -32.8px;
  }
}

@media (max-width: 991px) {
  .index .section1 .content .product-nav li .line {
    bottom: -27.4px;
  }
}

@media (max-width: 767px) {
  .index .section1 .content .product-nav li .line {
    bottom: -22px;
  }
}

.index .section1 .content .product-nav li.active a p {
  font-family: OPPOSans-H;
  font-weight: bold;
  color: #61ae8f;
}

.index .section1 .content .product-nav li.active .line {
  width: 100%;
}

.index .about-title {
  margin-bottom: 66px;
}

@media (max-width: 1600px) {
  .index .about-title {
    margin-bottom: 59.4px;
  }
}

@media (max-width: 1366px) {
  .index .about-title {
    margin-bottom: 52.8px;
  }
}

@media (max-width: 1024px) {
  .index .about-title {
    margin-bottom: 46.2px;
  }
}

@media (max-width: 991px) {
  .index .about-title {
    margin-bottom: 39.6px;
  }
}

@media (max-width: 767px) {
  .index .about-title {
    margin-bottom: 33px;
  }
}

.index .section2 {
  width: 100%;
  background-color: #f8f8f8;
  box-sizing: border-box;
  height: 735px;
}

@media (max-width: 767px) {
  .index .section2 {
    height: auto;
  }
}

.index .section2 .content {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  padding-right: 0;
}

@media (max-width: 767px) {
  .index .section2 .content {
    flex-wrap: wrap;
  }
}

@media (max-width: 767px) {
  .index .section2 .content {
    padding-right: 20px;
  }
}

.index .section2 .content .picture {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 53.45%;
  margin-top: 0;
  aspect-ratio: 1024 / 733;
}

@media (max-width: 767px) {
  .index .section2 .content .picture {
    position: static;
  }
}

@media (max-width: 767px) {
  .index .section2 .content .picture {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .index .section2 .content .picture {
    margin-top: 20px;
  }
}

.index .section2 .content .text {
  padding-top: 120px;
  padding-left: 13.75%;
}

@media (max-width: 1600px) {
  .index .section2 .content .text {
    padding-top: 100.8px;
  }
}

@media (max-width: 1366px) {
  .index .section2 .content .text {
    padding-top: 81.6px;
  }
}

@media (max-width: 1024px) {
  .index .section2 .content .text {
    padding-top: 62.4px;
  }
}

@media (max-width: 991px) {
  .index .section2 .content .text {
    padding-top: 43.2px;
  }
}

@media (max-width: 767px) {
  .index .section2 .content .text {
    padding-top: 24px;
  }
}

@media (max-width: 767px) {
  .index .section2 .content .text {
    padding-left: 20px;
  }
}

.index .section2 .content .text .nei-title {
  margin: 0;
  margin-bottom: 53px;
}

@media (max-width: 1600px) {
  .index .section2 .content .text .nei-title {
    margin-bottom: 47.7px;
  }
}

@media (max-width: 1366px) {
  .index .section2 .content .text .nei-title {
    margin-bottom: 42.4px;
  }
}

@media (max-width: 1024px) {
  .index .section2 .content .text .nei-title {
    margin-bottom: 37.1px;
  }
}

@media (max-width: 991px) {
  .index .section2 .content .text .nei-title {
    margin-bottom: 31.8px;
  }
}

@media (max-width: 767px) {
  .index .section2 .content .text .nei-title {
    margin-bottom: 26.5px;
  }
}

.index .section2 .content .text .nei-title h2 {
  font-size: 48px;
  letter-spacing: 1px;
  text-align: left;
  color: #61ae8f;
  line-height: 50px;
}

@media (max-width: 1600px) {
  .index .section2 .content .text .nei-title h2 {
    font-size: 44.4px;
  }
}

@media (max-width: 1366px) {
  .index .section2 .content .text .nei-title h2 {
    font-size: 40.8px;
  }
}

@media (max-width: 1024px) {
  .index .section2 .content .text .nei-title h2 {
    font-size: 37.2px;
  }
}

@media (max-width: 991px) {
  .index .section2 .content .text .nei-title h2 {
    font-size: 33.6px;
  }
}

@media (max-width: 767px) {
  .index .section2 .content .text .nei-title h2 {
    font-size: 30px;
  }
}

@media (max-width: 1600px) {
  .index .section2 .content .text .nei-title h2 {
    line-height: 47px;
  }
}

@media (max-width: 1366px) {
  .index .section2 .content .text .nei-title h2 {
    line-height: 44px;
  }
}

@media (max-width: 1024px) {
  .index .section2 .content .text .nei-title h2 {
    line-height: 41px;
  }
}

@media (max-width: 991px) {
  .index .section2 .content .text .nei-title h2 {
    line-height: 38px;
  }
}

@media (max-width: 767px) {
  .index .section2 .content .text .nei-title h2 {
    line-height: 35px;
  }
}

.index .section2 .content .text .nei-title span {
  margin-left: 0;
  margin-right: 0;
  margin-top: 40px;
}

@media (max-width: 1600px) {
  .index .section2 .content .text .nei-title span {
    margin-top: 36px;
  }
}

@media (max-width: 1366px) {
  .index .section2 .content .text .nei-title span {
    margin-top: 32px;
  }
}

@media (max-width: 1024px) {
  .index .section2 .content .text .nei-title span {
    margin-top: 28px;
  }
}

@media (max-width: 991px) {
  .index .section2 .content .text .nei-title span {
    margin-top: 24px;
  }
}

@media (max-width: 767px) {
  .index .section2 .content .text .nei-title span {
    margin-top: 20px;
  }
}

.index .section2 .content .text p {
  width: 37.2%;
  font-family: OPPOSans-L;
  font-size: 18px;
  line-height: 30px;
  color: #7b7b7b;
}

@media (max-width: 767px) {
  .index .section2 .content .text p {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .index .section2 .content .text p {
    font-size: 17.6px;
  }
}

@media (max-width: 1366px) {
  .index .section2 .content .text p {
    font-size: 17.2px;
  }
}

@media (max-width: 1024px) {
  .index .section2 .content .text p {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  .index .section2 .content .text p {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .index .section2 .content .text p {
    font-size: 16px;
  }
}

.index .section2 .content .text .more {
  display: flex;
  align-items: center;
  font-family: OPPOSans-L;
  font-size: 16px;
  color: #7B7B7B;
  margin-top: 105px;
}

@media (max-width: 1600px) {
  .index .section2 .content .text .more {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .index .section2 .content .text .more {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .index .section2 .content .text .more {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .index .section2 .content .text .more {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .index .section2 .content .text .more {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .index .section2 .content .text .more {
    margin-top: 92.4px;
  }
}

@media (max-width: 1366px) {
  .index .section2 .content .text .more {
    margin-top: 79.8px;
  }
}

@media (max-width: 1024px) {
  .index .section2 .content .text .more {
    margin-top: 67.2px;
  }
}

@media (max-width: 991px) {
  .index .section2 .content .text .more {
    margin-top: 54.6px;
  }
}

@media (max-width: 767px) {
  .index .section2 .content .text .more {
    margin-top: 42px;
  }
}

.index .section2 .content .text .more .pic {
  width: 58px;
  height: 58px;
  background-color: #61ae8f;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 15px;
}

@media (max-width: 1600px) {
  .index .section2 .content .text .more .pic {
    width: 54.4px;
  }
}

@media (max-width: 1366px) {
  .index .section2 .content .text .more .pic {
    width: 50.8px;
  }
}

@media (max-width: 1024px) {
  .index .section2 .content .text .more .pic {
    width: 47.2px;
  }
}

@media (max-width: 991px) {
  .index .section2 .content .text .more .pic {
    width: 43.6px;
  }
}

@media (max-width: 767px) {
  .index .section2 .content .text .more .pic {
    width: 40px;
  }
}

@media (max-width: 1600px) {
  .index .section2 .content .text .more .pic {
    height: 54.4px;
  }
}

@media (max-width: 1366px) {
  .index .section2 .content .text .more .pic {
    height: 50.8px;
  }
}

@media (max-width: 1024px) {
  .index .section2 .content .text .more .pic {
    height: 47.2px;
  }
}

@media (max-width: 991px) {
  .index .section2 .content .text .more .pic {
    height: 43.6px;
  }
}

@media (max-width: 767px) {
  .index .section2 .content .text .more .pic {
    height: 40px;
  }
}

.index .section2a {
  margin-top: 160px;
  margin-bottom: 160px;
}

@media (max-width: 1600px) {
  .index .section2a {
    margin-top: 134px;
  }
}

@media (max-width: 1366px) {
  .index .section2a {
    margin-top: 108px;
  }
}

@media (max-width: 1024px) {
  .index .section2a {
    margin-top: 82px;
  }
}

@media (max-width: 991px) {
  .index .section2a {
    margin-top: 56px;
  }
}

@media (max-width: 767px) {
  .index .section2a {
    margin-top: 30px;
  }
}

@media (max-width: 1600px) {
  .index .section2a {
    margin-bottom: 134px;
  }
}

@media (max-width: 1366px) {
  .index .section2a {
    margin-bottom: 108px;
  }
}

@media (max-width: 1024px) {
  .index .section2a {
    margin-bottom: 82px;
  }
}

@media (max-width: 991px) {
  .index .section2a {
    margin-bottom: 56px;
  }
}

@media (max-width: 767px) {
  .index .section2a {
    margin-bottom: 30px;
  }
}

.index .section2a ul {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 655px;
}

@media (max-width: 1600px) {
  .index .section2a ul {
    height: 600px;
  }
}

@media (max-width: 1366px) {
  .index .section2a ul {
    height: 500px;
  }
}

@media (max-width: 1024px) {
  .index .section2a ul {
    height: 400px;
  }
}

@media (max-width: 767px) {
  .index .section2a ul {
    height: auto;
  }
}

.index .section2a ul li {
  position: relative;
  margin-top: 2px;
  width: 204px;
  transition: all .5s;
  margin-bottom: 0;
  overflow: hidden;
}

@media (max-width: 767px) {
  .index .section2a ul li {
    margin-top: 0;
  }
}

@media (max-width: 1600px) {
  .index .section2a ul li {
    width: 12.977%;
  }
}

@media (max-width: 767px) {
  .index .section2a ul li {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .index .section2a ul li {
    margin-bottom: 20px;
  }
}

.index .section2a ul li.active {
  width: 1162px;
}

@media (max-width: 1600px) {
  .index .section2a ul li.active {
    width: 73.918%;
  }
}

@media (max-width: 767px) {
  .index .section2a ul li.active {
    width: 100%;
  }
}

.index .section2a ul li.active .text {
  opacity: 1;
}

.index .section2a ul li.active .model {
  opacity: 0;
}

.index .section2a ul li:last-child {
  margin-right: 0;
}

.index .section2a ul li .pic {
  width: 100%;
  height: 100%;
  aspect-ratio: none;
}

@media (max-width: 767px) {
  .index .section2a ul li .pic {
    height: auto;
  }
}

@media (max-width: 767px) {
  .index .section2a ul li .pic {
    aspect-ratio: 1.78221;
  }
}

.index .section2a ul li .model {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  transition: all .5s;
  display: block;
}

@media (max-width: 767px) {
  .index .section2a ul li .model {
    display: none;
  }
}

.index .section2a ul li .text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 60px;
  opacity: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 2;
  height: 235px;
  transition: all .5s;
}

@media (max-width: 767px) {
  .index .section2a ul li .text {
    position: static;
  }
}

@media (max-width: 1600px) {
  .index .section2a ul li .text {
    padding: 52px;
  }
}

@media (max-width: 1366px) {
  .index .section2a ul li .text {
    padding: 44px;
  }
}

@media (max-width: 1024px) {
  .index .section2a ul li .text {
    padding: 36px;
  }
}

@media (max-width: 991px) {
  .index .section2a ul li .text {
    padding: 28px;
  }
}

@media (max-width: 767px) {
  .index .section2a ul li .text {
    padding: 20px;
  }
}

@media (max-width: 767px) {
  .index .section2a ul li .text {
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .index .section2a ul li .text {
    display: block;
  }
}

.index .section2a ul li .text .img {
  width: 100px;
  height: 100px;
  margin-right: 55px;
  margin-bottom: 0;
}

@media (max-width: 1600px) {
  .index .section2a ul li .text .img {
    width: 92px;
  }
}

@media (max-width: 1366px) {
  .index .section2a ul li .text .img {
    width: 84px;
  }
}

@media (max-width: 1024px) {
  .index .section2a ul li .text .img {
    width: 76px;
  }
}

@media (max-width: 991px) {
  .index .section2a ul li .text .img {
    width: 68px;
  }
}

@media (max-width: 767px) {
  .index .section2a ul li .text .img {
    width: 60px;
  }
}

@media (max-width: 1600px) {
  .index .section2a ul li .text .img {
    height: 92px;
  }
}

@media (max-width: 1366px) {
  .index .section2a ul li .text .img {
    height: 84px;
  }
}

@media (max-width: 1024px) {
  .index .section2a ul li .text .img {
    height: 76px;
  }
}

@media (max-width: 991px) {
  .index .section2a ul li .text .img {
    height: 68px;
  }
}

@media (max-width: 767px) {
  .index .section2a ul li .text .img {
    height: 60px;
  }
}

@media (max-width: 1600px) {
  .index .section2a ul li .text .img {
    margin-right: 45px;
  }
}

@media (max-width: 1366px) {
  .index .section2a ul li .text .img {
    margin-right: 35px;
  }
}

@media (max-width: 1024px) {
  .index .section2a ul li .text .img {
    margin-right: 25px;
  }
}

@media (max-width: 767px) {
  .index .section2a ul li .text .img {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .index .section2a ul li .text .img {
    margin-bottom: 15px;
  }
}

.index .section2a ul li .text .img img {
  width: 100%;
}

.index .section2a ul li .text .verison {
  width: 770px;
}

@media (max-width: 1600px) {
  .index .section2a ul li .text .verison {
    width: 66.26%;
  }
}

@media (max-width: 767px) {
  .index .section2a ul li .text .verison {
    width: 100%;
  }
}

.index .section2a ul li .text h2 {
  font-family: OPPOSans-B;
  font-size: 36px;
  color: #fff;
  margin-bottom: 25px;
  font-weight: bold;
}

@media (max-width: 1600px) {
  .index .section2a ul li .text h2 {
    font-size: 33.6px;
  }
}

@media (max-width: 1366px) {
  .index .section2a ul li .text h2 {
    font-size: 31.2px;
  }
}

@media (max-width: 1024px) {
  .index .section2a ul li .text h2 {
    font-size: 28.8px;
  }
}

@media (max-width: 991px) {
  .index .section2a ul li .text h2 {
    font-size: 26.4px;
  }
}

@media (max-width: 767px) {
  .index .section2a ul li .text h2 {
    font-size: 24px;
  }
}

@media (max-width: 1600px) {
  .index .section2a ul li .text h2 {
    margin-bottom: 22.5px;
  }
}

@media (max-width: 1366px) {
  .index .section2a ul li .text h2 {
    margin-bottom: 20px;
  }
}

@media (max-width: 1024px) {
  .index .section2a ul li .text h2 {
    margin-bottom: 17.5px;
  }
}

@media (max-width: 991px) {
  .index .section2a ul li .text h2 {
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .index .section2a ul li .text h2 {
    margin-bottom: 12.5px;
  }
}

.index .section2a ul li .text p {
  font-family: OPPOSans-L;
  font-size: 18px;
  line-height: 30px;
  transition: all .3s;
  color: #fff;
}

@media (max-width: 1600px) {
  .index .section2a ul li .text p {
    font-size: 17.6px;
  }
}

@media (max-width: 1366px) {
  .index .section2a ul li .text p {
    font-size: 17.2px;
  }
}

@media (max-width: 1024px) {
  .index .section2a ul li .text p {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  .index .section2a ul li .text p {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .index .section2a ul li .text p {
    font-size: 16px;
  }
}

.index .section2a ul li:nth-child(1) .text {
  background: rgba(0, 166, 108, 0.9);
}

.index .section2a ul li:nth-child(1) .model {
  background: rgba(0, 166, 108, 0.4);
}

.index .section2a ul li:nth-child(2) .text {
  background: rgba(249, 60, 6, 0.9);
}

.index .section2a ul li:nth-child(2) .model {
  background: rgba(249, 60, 6, 0.4);
}

.index .section2a ul li:nth-child(3) .text {
  background: rgba(60, 86, 207, 0.9);
}

.index .section2a ul li:nth-child(3) .model {
  background: rgba(60, 86, 207, 0.4);
}

.index .video {
  background-color: #f8f8f8;
  padding-top: 120px;
  padding-bottom: 120px;
}

@media (max-width: 1600px) {
  .index .video {
    padding-top: 102px;
  }
}

@media (max-width: 1366px) {
  .index .video {
    padding-top: 84px;
  }
}

@media (max-width: 1024px) {
  .index .video {
    padding-top: 66px;
  }
}

@media (max-width: 991px) {
  .index .video {
    padding-top: 48px;
  }
}

@media (max-width: 767px) {
  .index .video {
    padding-top: 30px;
  }
}

@media (max-width: 1600px) {
  .index .video {
    padding-bottom: 102px;
  }
}

@media (max-width: 1366px) {
  .index .video {
    padding-bottom: 84px;
  }
}

@media (max-width: 1024px) {
  .index .video {
    padding-bottom: 66px;
  }
}

@media (max-width: 991px) {
  .index .video {
    padding-bottom: 48px;
  }
}

@media (max-width: 767px) {
  .index .video {
    padding-bottom: 30px;
  }
}

.index .video .container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.index .video .container .content {
  width: 45%;
  position: relative;
}

@media (max-width: 767px) {
  .index .video .container .content {
    width: 100%;
  }
}

.index .video .container .content video {
  width: 100%;
  max-width: 100%;
  aspect-ratio: 1576 / 886;
  object-fit: cover;
}

.index .video .container .content .play {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  top: 0;
  left: 0;
}

.index .video .container .text {
  width: 50%;
  margin-top: 40px;
}

@media (max-width: 767px) {
  .index .video .container .text {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .index .video .container .text {
    margin-top: 38px;
  }
}

@media (max-width: 1366px) {
  .index .video .container .text {
    margin-top: 36px;
  }
}

@media (max-width: 1024px) {
  .index .video .container .text {
    margin-top: 34px;
  }
}

@media (max-width: 991px) {
  .index .video .container .text {
    margin-top: 32px;
  }
}

@media (max-width: 767px) {
  .index .video .container .text {
    margin-top: 30px;
  }
}

.index .video .container .text .nei-title {
  margin: 0;
  margin-bottom: 53px;
}

@media (max-width: 1600px) {
  .index .video .container .text .nei-title {
    margin-bottom: 47.7px;
  }
}

@media (max-width: 1366px) {
  .index .video .container .text .nei-title {
    margin-bottom: 42.4px;
  }
}

@media (max-width: 1024px) {
  .index .video .container .text .nei-title {
    margin-bottom: 37.1px;
  }
}

@media (max-width: 991px) {
  .index .video .container .text .nei-title {
    margin-bottom: 31.8px;
  }
}

@media (max-width: 767px) {
  .index .video .container .text .nei-title {
    margin-bottom: 26.5px;
  }
}

.index .video .container .text .nei-title h2 {
  font-size: 48px;
  letter-spacing: 1px;
  text-align: left;
  color: #61ae8f;
  line-height: 50px;
}

@media (max-width: 1600px) {
  .index .video .container .text .nei-title h2 {
    font-size: 44.4px;
  }
}

@media (max-width: 1366px) {
  .index .video .container .text .nei-title h2 {
    font-size: 40.8px;
  }
}

@media (max-width: 1024px) {
  .index .video .container .text .nei-title h2 {
    font-size: 37.2px;
  }
}

@media (max-width: 991px) {
  .index .video .container .text .nei-title h2 {
    font-size: 33.6px;
  }
}

@media (max-width: 767px) {
  .index .video .container .text .nei-title h2 {
    font-size: 30px;
  }
}

@media (max-width: 1600px) {
  .index .video .container .text .nei-title h2 {
    line-height: 47px;
  }
}

@media (max-width: 1366px) {
  .index .video .container .text .nei-title h2 {
    line-height: 44px;
  }
}

@media (max-width: 1024px) {
  .index .video .container .text .nei-title h2 {
    line-height: 41px;
  }
}

@media (max-width: 991px) {
  .index .video .container .text .nei-title h2 {
    line-height: 38px;
  }
}

@media (max-width: 767px) {
  .index .video .container .text .nei-title h2 {
    line-height: 35px;
  }
}

.index .video .container .text .nei-title span {
  margin-left: 0;
  margin-right: 0;
  margin-top: 40px;
}

@media (max-width: 1600px) {
  .index .video .container .text .nei-title span {
    margin-top: 36px;
  }
}

@media (max-width: 1366px) {
  .index .video .container .text .nei-title span {
    margin-top: 32px;
  }
}

@media (max-width: 1024px) {
  .index .video .container .text .nei-title span {
    margin-top: 28px;
  }
}

@media (max-width: 991px) {
  .index .video .container .text .nei-title span {
    margin-top: 24px;
  }
}

@media (max-width: 767px) {
  .index .video .container .text .nei-title span {
    margin-top: 20px;
  }
}

.index .video .container .text p {
  font-family: OPPOSans-L;
  font-size: 18px;
  line-height: 30px;
  color: #7b7b7b;
}

@media (max-width: 1600px) {
  .index .video .container .text p {
    font-size: 17.6px;
  }
}

@media (max-width: 1366px) {
  .index .video .container .text p {
    font-size: 17.2px;
  }
}

@media (max-width: 1024px) {
  .index .video .container .text p {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  .index .video .container .text p {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .index .video .container .text p {
    font-size: 16px;
  }
}

.index .video .container .text .more {
  display: flex;
  align-items: center;
  font-family: OPPOSans-L;
  font-size: 16px;
  color: #7B7B7B;
  margin-top: 70px;
}

@media (max-width: 1600px) {
  .index .video .container .text .more {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .index .video .container .text .more {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .index .video .container .text .more {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .index .video .container .text .more {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .index .video .container .text .more {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .index .video .container .text .more {
    margin-top: 61.6px;
  }
}

@media (max-width: 1366px) {
  .index .video .container .text .more {
    margin-top: 53.2px;
  }
}

@media (max-width: 1024px) {
  .index .video .container .text .more {
    margin-top: 44.8px;
  }
}

@media (max-width: 991px) {
  .index .video .container .text .more {
    margin-top: 36.4px;
  }
}

@media (max-width: 767px) {
  .index .video .container .text .more {
    margin-top: 28px;
  }
}

.index .video .container .text .more .pic {
  width: 58px;
  height: 58px;
  background-color: #61ae8f;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 15px;
}

@media (max-width: 1600px) {
  .index .video .container .text .more .pic {
    width: 54.4px;
  }
}

@media (max-width: 1366px) {
  .index .video .container .text .more .pic {
    width: 50.8px;
  }
}

@media (max-width: 1024px) {
  .index .video .container .text .more .pic {
    width: 47.2px;
  }
}

@media (max-width: 991px) {
  .index .video .container .text .more .pic {
    width: 43.6px;
  }
}

@media (max-width: 767px) {
  .index .video .container .text .more .pic {
    width: 40px;
  }
}

@media (max-width: 1600px) {
  .index .video .container .text .more .pic {
    height: 54.4px;
  }
}

@media (max-width: 1366px) {
  .index .video .container .text .more .pic {
    height: 50.8px;
  }
}

@media (max-width: 1024px) {
  .index .video .container .text .more .pic {
    height: 47.2px;
  }
}

@media (max-width: 991px) {
  .index .video .container .text .more .pic {
    height: 43.6px;
  }
}

@media (max-width: 767px) {
  .index .video .container .text .more .pic {
    height: 40px;
  }
}

.index .section3 {
  margin-top: 114px;
  margin-bottom: 120px;
}

@media (max-width: 1600px) {
  .index .section3 {
    margin-top: 97.2px;
  }
}

@media (max-width: 1366px) {
  .index .section3 {
    margin-top: 80.4px;
  }
}

@media (max-width: 1024px) {
  .index .section3 {
    margin-top: 63.6px;
  }
}

@media (max-width: 991px) {
  .index .section3 {
    margin-top: 46.8px;
  }
}

@media (max-width: 767px) {
  .index .section3 {
    margin-top: 30px;
  }
}

@media (max-width: 1600px) {
  .index .section3 {
    margin-bottom: 102px;
  }
}

@media (max-width: 1366px) {
  .index .section3 {
    margin-bottom: 84px;
  }
}

@media (max-width: 1024px) {
  .index .section3 {
    margin-bottom: 66px;
  }
}

@media (max-width: 991px) {
  .index .section3 {
    margin-bottom: 48px;
  }
}

@media (max-width: 767px) {
  .index .section3 {
    margin-bottom: 30px;
  }
}

.index .section3 .container ul {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 78px;
}

@media (max-width: 1600px) {
  .index .section3 .container ul {
    margin-top: 67.4px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul {
    margin-top: 56.8px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container ul {
    margin-top: 46.2px;
  }
}

@media (max-width: 991px) {
  .index .section3 .container ul {
    margin-top: 35.6px;
  }
}

@media (max-width: 767px) {
  .index .section3 .container ul {
    margin-top: 25px;
  }
}

.index .section3 .container ul li {
  width: 510px;
  box-shadow: 0px 2px 3px 1px rgba(165, 165, 165, 0.22);
  border-radius: 5px;
  margin-bottom: 44px;
  position: relative;
  transition: all .3s;
  top: 0;
}

@media (max-width: 1600px) {
  .index .section3 .container ul li {
    width: 32.36%;
  }
}

@media (max-width: 767px) {
  .index .section3 .container ul li {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .index .section3 .container ul li {
    margin-bottom: 39.6px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul li {
    margin-bottom: 35.2px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container ul li {
    margin-bottom: 30.8px;
  }
}

@media (max-width: 991px) {
  .index .section3 .container ul li {
    margin-bottom: 26.4px;
  }
}

@media (max-width: 767px) {
  .index .section3 .container ul li {
    margin-bottom: 22px;
  }
}

.index .section3 .container ul li .pic {
  aspect-ratio: 510 / 278;
  overflow: hidden;
}

.index .section3 .container ul li .pic img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all .3s;
}

.index .section3 .container ul li .text {
  box-sizing: border-box;
  padding-top: 40px;
  padding-left: 45px;
  padding-bottom: 47px;
  padding-right: 45px;
  font-family: ArialMT;
  color: #4d504f;
  transition: all .3s;
}

@media (max-width: 1600px) {
  .index .section3 .container ul li .text {
    padding-top: 34.66667px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul li .text {
    padding-top: 29.33333px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container ul li .text {
    padding-top: 24px;
  }
}

@media (max-width: 991px) {
  .index .section3 .container ul li .text {
    padding-top: 18.66667px;
  }
}

@media (max-width: 767px) {
  .index .section3 .container ul li .text {
    padding-top: 13.33333px;
  }
}

@media (max-width: 1600px) {
  .index .section3 .container ul li .text {
    padding-left: 39px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul li .text {
    padding-left: 33px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container ul li .text {
    padding-left: 27px;
  }
}

@media (max-width: 991px) {
  .index .section3 .container ul li .text {
    padding-left: 21px;
  }
}

@media (max-width: 767px) {
  .index .section3 .container ul li .text {
    padding-left: 15px;
  }
}

@media (max-width: 1600px) {
  .index .section3 .container ul li .text {
    padding-bottom: 40.73333px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul li .text {
    padding-bottom: 34.46667px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container ul li .text {
    padding-bottom: 28.2px;
  }
}

@media (max-width: 991px) {
  .index .section3 .container ul li .text {
    padding-bottom: 21.93333px;
  }
}

@media (max-width: 767px) {
  .index .section3 .container ul li .text {
    padding-bottom: 15.66667px;
  }
}

@media (max-width: 1600px) {
  .index .section3 .container ul li .text {
    padding-right: 39px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul li .text {
    padding-right: 33px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container ul li .text {
    padding-right: 27px;
  }
}

@media (max-width: 991px) {
  .index .section3 .container ul li .text {
    padding-right: 21px;
  }
}

@media (max-width: 767px) {
  .index .section3 .container ul li .text {
    padding-right: 15px;
  }
}

.index .section3 .container ul li .text h2 {
  font-size: 24px;
  line-height: 41px;
  margin-bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

@media (max-width: 1600px) {
  .index .section3 .container ul li .text h2 {
    font-size: 23.2px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul li .text h2 {
    font-size: 22.4px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container ul li .text h2 {
    font-size: 21.6px;
  }
}

@media (max-width: 991px) {
  .index .section3 .container ul li .text h2 {
    font-size: 20.8px;
  }
}

@media (max-width: 767px) {
  .index .section3 .container ul li .text h2 {
    font-size: 20px;
  }
}

@media (max-width: 1600px) {
  .index .section3 .container ul li .text h2 {
    line-height: 38.95px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul li .text h2 {
    line-height: 36.9px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container ul li .text h2 {
    line-height: 34.85px;
  }
}

@media (max-width: 991px) {
  .index .section3 .container ul li .text h2 {
    line-height: 32.8px;
  }
}

@media (max-width: 767px) {
  .index .section3 .container ul li .text h2 {
    line-height: 30.75px;
  }
}

.index .section3 .container ul li .text p {
  font-family: OpenSans;
  font-size: 18px;
  color: #4d504f;
  line-height: 30px;
  margin-bottom: 40px;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

@media (max-width: 1600px) {
  .index .section3 .container ul li .text p {
    font-size: 17.6px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul li .text p {
    font-size: 17.2px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container ul li .text p {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  .index .section3 .container ul li .text p {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .index .section3 .container ul li .text p {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index .section3 .container ul li .text p {
    margin-bottom: 36px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul li .text p {
    margin-bottom: 32px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container ul li .text p {
    margin-bottom: 28px;
  }
}

@media (max-width: 991px) {
  .index .section3 .container ul li .text p {
    margin-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .index .section3 .container ul li .text p {
    margin-bottom: 20px;
  }
}

.index .section3 .container ul li .text .more {
  font-family: ArialMT;
  font-size: 18px;
}

@media (max-width: 1600px) {
  .index .section3 .container ul li .text .more {
    font-size: 17.6px;
  }
}

@media (max-width: 1366px) {
  .index .section3 .container ul li .text .more {
    font-size: 17.2px;
  }
}

@media (max-width: 1024px) {
  .index .section3 .container ul li .text .more {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  .index .section3 .container ul li .text .more {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .index .section3 .container ul li .text .more {
    font-size: 16px;
  }
}

.index .section3 .container ul li:hover {
  top: -5px;
}

.index .section3 .container ul li:hover .pic img {
  transform: scale(1.1);
}

.index .section3 .container ul li:hover .text {
  font-weight: bold;
  color: #61AE8F;
}

.index .section3 .container ul .placeholder {
  width: 510px;
}

@media (max-width: 1600px) {
  .index .section3 .container ul .placeholder {
    width: 32.36%;
  }
}

@media (max-width: 767px) {
  .index .section3 .container ul .placeholder {
    width: 100%;
  }
}
