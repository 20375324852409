@import "../../assets/styles/utils";
.index-banner {
    @include res(height,800px,(350 / 800));
    @include res(margin-top,122px,(sm:60px));
    .swiper {
        height: 100%;
        position: relative;
        .swiper-slide {
            position: relative;
            width: 100%;
            height: 100%;
            .title {
                position: absolute;
                color: #fff;
                @include res(top,32%,(xs:40px));
                font-family: OPPOSans-H;
                left: 0;
                right: 0;
                h2 {
                    @include res(font-size,63px,(2 / 3));
                    @include res(line-height,82px,(2 / 3));
                    font-weight: bold;
                    margin-bottom: 28px;
                    letter-spacing: 1px;
                }
                p {
                    font-family: OPPOSans-L1;
                    @include res(font-size,27px,(2 / 3));
                    @include res(line-height,34px,(3 / 4));
                    letter-spacing: 1px;
                }
            }
        }
        .swiper-control {
            @include res(bottom,0.73rem,(xs:40px));
            left: 0;
            right: 0;
            position: absolute;
            .swiper-page {
                width: max-content;
                position: relative;
                bottom: 0;
                left: 0;
                right: auto;
                display: flex;
                align-items: center;
                z-index: 1;
                .swiper-pagination-bullet {
                    width: 9px;
                    height: 9px;
                    background-color: transparent;
                    border: 1px solid #fff;
                    opacity: 1;
                    margin: 0;
                    @include res(margin-right,47px);
                    position: relative;
                    &:last-child {
                        margin-right: 0;
                    }
                }
                .swiper-pagination-bullet-active {
                    width: 12px;
                    height: 12px;
                    background: #fff;
                }
            }
            .line {
                width: 100%;
                border-bottom: 1px solid #fff;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                z-index: 1;
            }
        }
    }
}
.index {
    .section1 {
        @include res(margin-top,85px,(30 / 85));
        @include res(padding-bottom,148px,(30 / 148));
        .content {
            @include res(margin-top,66px,(25 / 66));
            .product-list {
                @include res(margin-bottom,85px,(25 / 85));
                ul {
                    display: none;
                    align-items: stretch;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    &.active {
                        display: flex;
                    }
                    li {  
                        background-color: #f8f8f8;
                        @include res(width,32%,(xs:100%));
                        @include res(padding-left,50px,(1 / 5));
                        @include res(padding-right,50px,(1 / 5));
                        @include res(padding-top,48px,(1 / 5));
                        @include res(padding-bottom,54px,(1 / 5));
                        @include res(margin-bottom,0,(xs:15px));
                        box-sizing: border-box;
                        position: relative;
                        transition: all .5s;
                        &.active {
                            @include res(width,760px,(lg:48%,xs:100%));
                            @include res(padding-left,58px,(1 / 5));
                            @include res(padding-right,58px,(1 / 5));
                            .more {
                                display: flex;
                            }
                            .text {
                                display: flex;
                                p {
                                    @include res(width,245px,(xs:100%));
                                }
                            }
                        }
                        &:hover {
                            .title {
                                h2 {
                                    color: #61AE8F;
                                }
                                span {
                                    background: #61AE8F;
                                }
                            }
                        }
                        .title {
                            h2 {
                                font-family: OPPOSans-L;
                                @include res(font-size,28px,(22 / 28));
                                color: #7b7b7b;
                                transition: all .3s;
                            }
                            span {
                                display: block;
                                width: 21px;
                                height: 2px;
                                background-color: #7b7b7b;
                                @include res(margin-top,14px,(10 / 14));
                                transition: all .3s;
                            }
                        }
                        .text {
                            display: block;
                            align-items: stretch;
                            justify-content: space-between;
                            @include res(flex-wrap,nowrap,(xs:wrap));
                            @include res(margin-top,30px,(1 / 2));
                            p {
                                font-family: OPPOSans-L;
                                @include res(font-size,16px,(14 / 16));
                                line-height: 25px;
                                color: #7B7B7B;
                                width: 100%;
                            }
                            .pic {
                                @include res(margin-top,30px,(1 / 2));
                                @include res(width,100%,(mmd:90%,md:80%,xs:60%));
                                margin: auto;
                                aspect-ratio: 394 / 372;
                            }
                        }
                        .more {
                            position: absolute;
                            @include res(bottom,54px,(1 / 5));
                            @include res(left,58px,(1 / 5));
                            display: none;
                            align-items: center;
                            justify-content: center;
                            font-family: OPPOSans-L;
                            @include res(font-size,16px,(14 / 16));
                            color: #7B7B7B;
                            .pic {
                                @include res(width,58px,(40 / 58));
                                @include res(height,58px,(40 / 58));
                                background-color: #61ae8f;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 50%;
                                margin-right: 15px;
                            }
                        }
                    }
                }
            }
            .product-nav {
                overflow: hidden;
                overflow-x: auto;
                padding-bottom: 10px;
                &::-webkit-scrollbar {
                    width: 4px;
                    height: 4px;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                    opacity: 0.2;
                    background: #61AE8F;
                }
                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                    border-radius: 0;
                    background: fade(#fff, 30%);
                }
                ul {
                    display: flex;
                    align-items: center;
                    @include res(justify-content,space-between,(mmd:flex-start));
                    @include res(padding-bottom,47px,(20 / 47));
                    border-bottom: 2px solid #eeeeee;
                }
                li {
                    position: relative;
                    white-space: nowrap;
                    @include res(margin-right,0,(mmd:20px,xs:10px));
                    a {
                        display: flex;
                        align-items: center;
                        img {
                            @include res(margin-right,14px,(10 / 14));
                        }
                        p {
                            font-family: OPPOSans-L;
                            @include res(font-size,22px,(16 / 22));
                            color: #7B7B7B;
                            transition: all .3s;
                        }
                    }
                    .line {
                        position: absolute;
                        width: 0;
                        height: 3px;
	                    background-color: #61ae8f;
                        @include res(bottom,-49px,(22 / 49));
                        left: 0;
                        transition: all .3s;
                    }
                    &.active {
                        a {
                            p {
                                font-family: OPPOSans-H;
                                font-weight: bold;
                                color: #61ae8f;
                            }
                        }
                        .line {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .about-title {
        @include res(margin-bottom,66px,(1 / 2));
    }
    .section2 {
        width: 100%;
        background-color: #f8f8f8;
        box-sizing: border-box;
        @include res(height,735px,(xs:auto));
        .content {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            @include res(flex-wrap,nowrap,(xs:wrap));
            height: 100%;
            position: relative;
            box-sizing: border-box;
            @include res(padding-right,0,(xs:20px));
            .picture {
                @include res(position,absolute,(xs:static));
                bottom: 0;
                right: 0;
                @include res(width,53.45%,(xs:100%));
                @include res(margin-top,0,(xs:20px));
                aspect-ratio: 1024 / 733;
            }
            .text {
                @include res(padding-top,120px,(1 / 5));
                @include res(padding-left,13.75%,(xs:20px));
                .nei-title {
                    margin: 0;
                    @include res(margin-bottom,53px,(1 / 2));
                    h2 {
                        @include res(font-size,48px,(30 / 48));
                        letter-spacing: 1px;
                        text-align: left;
                        color: #61ae8f;
                        @include res(line-height,50px,(35 / 50));
                        
                    }
                    span {
                        margin-left: 0;
                        margin-right: 0;
                        @include res(margin-top,40px,(1 / 2));
                    }
                }
                p {
                    @include res(width,37.2%,(xs:100%));
                    font-family: OPPOSans-L;
                    @include res(font-size,18px,(16 / 18));
                    line-height: 30px;
                    color: #7b7b7b;
                }
                .more {
                    display: flex;
                    align-items: center;
                    font-family: OPPOSans-L;
                    @include res(font-size,16px,(14 / 16));
                    color: #7B7B7B;
                    @include res(margin-top,105px,(2 / 5));
                    .pic {
                        @include res(width,58px,(40 / 58));
                        @include res(height,58px,(40 / 58));
                        background-color: #61ae8f;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        margin-right: 15px;
                    }
                }
            }
        }
    }
    .section2a {
        @include res(margin-top,160px,(3 / 16));
        @include res(margin-bottom,160px,(3 / 16));
        ul {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            flex-wrap: wrap;
            @include res(height,655px,(lg:600px,mmd:500px,md:400px,xs:auto));
            li {
                position: relative;
                @include res(margin-top,2px,(xs:0));
                @include res(width,204px,(lg:12.977%,xs:100%));
                transition: all .5s;
                @include res(margin-bottom,0,(xs:20px));
                overflow: hidden;
                &.active {
                    @include res(width,1162px,(lg:73.918%,xs:100%));
                    .text {
                        opacity: 1;
                    }
                    .model {
                        opacity: 0;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
                .pic {
                    width: 100%;
                    @include res(height,100%,(xs:auto));
                    @include res(aspect-ratio,none,(xs:581 / 326));
                }
                .model {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    background: rgba(0,0,0,.4);
                    transition: all .5s;
                    @include res(display,block,(xs:none));
                }
                .text {
                    @include res(position,absolute,(xs:static));
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    box-sizing: border-box;
                    @include res(padding,60px,(1 / 3));
                    @include res(opacity,0,(xs:1));
                    align-items: center;
                    justify-content: center;
                    @include res(display,flex,(xs:block));
                    z-index: 2;
                    @include res(height,235px);
                    transition: all .5s;
                    .img {
                        @include res(width,100px,(3 / 5));
                        @include res(height,100px,(3 / 5));
                        @include res(margin-right,55px,(lg:45px,mmd:35px,md:25px,xs:0));
                        @include res(margin-bottom,0,(xs:15px));
                        img {
                            width: 100%;
                        }
                    }
                    .verison {
                        @include res(width,770px,(lg:66.26%,xs:100%));
                    }
                    h2 {
                        font-family: OPPOSans-B;
                        @include res(font-size,36px,(24 / 36));
                        color: #fff;
                        @include res(margin-bottom,25px,(1 / 2));
                        font-weight: bold;
                    }
                    p {
                        font-family: OPPOSans-L;
                        @include res(font-size,18px,(16 / 18));
                        line-height: 30px;
                        transition: all .3s;
                        color: #fff;
                    }
                }
                &:nth-child(1) {
                    .text {
                        @include res(background,rgba(0,166,108,.9));
                    }
                    .model {
                        background: rgba(0,166,108,.4);
                    }
                }
                &:nth-child(2) {
                    .text {
                        @include res(background,rgba(249,60,6,.9));
                    }
                    .model {
                        background: rgba(249,60,6,.4);
                    }
                }
                &:nth-child(3) {
                    .text {
                        @include res(background,rgba(60,86,207,.9));
                    }
                    .model {
                        background: rgba(60,86,207,.4);
                    }
                }
            }
        }
    }
    .video {
        background-color:#f8f8f8;
        @include res(padding-top,120px,(1 / 4));
        @include res(padding-bottom,120px,(1 / 4));
        .container {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            .content {
                @include res(width,45%,(xs:100%));
                position: relative;
                video {
                    width: 100%;
                    max-width: 100%;
                    aspect-ratio: 1576 / 886;
                    object-fit: cover;
                }
                .play {
                    position: absolute;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    background: rgba(0,0,0,.06);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                }
            }
            .text {
                @include res(width,50%,(xs:100%));
                @include res(margin-top,40px,(3 / 4));
                .nei-title {
                    margin: 0;
                    @include res(margin-bottom,53px,(1 / 2));
                    h2 {
                        @include res(font-size,48px,(30 / 48));
                        letter-spacing: 1px;
                        text-align: left;
                        color: #61ae8f;
                        @include res(line-height,50px,(35 / 50));
                        
                    }
                    span {
                        margin-left: 0;
                        margin-right: 0;
                        @include res(margin-top,40px,(1 / 2));
                    }
                }
                p {
                    font-family: OPPOSans-L;
                    @include res(font-size,18px,(16 / 18));
                    line-height: 30px;
                    color: #7b7b7b;
                }
                .more {
                    display: flex;
                    align-items: center;
                    font-family: OPPOSans-L;
                    @include res(font-size,16px,(14 / 16));
                    color: #7B7B7B;
                    @include res(margin-top,70px,(2 / 5));
                    .pic {
                        @include res(width,58px,(40 / 58));
                        @include res(height,58px,(40 / 58));
                        background-color: #61ae8f;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        margin-right: 15px;
                    }
                }
                
            }
        }
    }
    .section3 {
        @include res(margin-top,114px,(30 / 114));
        @include res(margin-bottom,120px,(1 / 4));
        .container {
            ul {
                display: flex;
                align-items: stretch;
                justify-content: space-between;
                flex-wrap: wrap;
                @include res(margin-top,78px,(25 / 78));
                li {
                    @include res(width,510px,(lg:32.36%,xs:100%));
                    box-shadow: 0px 2px 3px 1px rgba(165, 165, 165, 0.22);
	                border-radius: 5px;
                    @include res(margin-bottom,44px,(1 / 2));
                    position: relative;
                    transition: all .3s;
                    top: 0;
                    .pic {
                        aspect-ratio: 510 / 278;
                        overflow: hidden;
                        img {
                            width: 100%;
                            max-width: 100%;
                            height: 100%;
                            object-fit: cover;
                            transition: all .3s;
                        }
                    }
                    .text {
                        box-sizing: border-box;
                        @include res(padding-top,40px,(1 / 3));
                        @include res(padding-left,45px,(1 / 3));
                        @include res(padding-bottom,47px,(1 / 3));
                        @include res(padding-right,45px,(1 / 3));
                        font-family: ArialMT;
                        color: #4d504f;
                        transition: all .3s;
                        h2 {
                            @include res(font-size,24px,(5 / 6));
                            @include res(line-height,41px,(3 / 4));
                            margin-bottom: 15px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                        }
                        p {
                            font-family: OpenSans;
                            @include res(font-size,18px,(16 / 18));
                            color: #4d504f;
                            line-height: 30px;
                            @include res(margin-bottom,40px,(1 / 2));
                            font-weight: normal;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 5;
                        }
                        .more {
                            font-family: ArialMT;
                            @include res(font-size,18px,(16 / 18));
                        }
                    }
                    &:hover {
                        top: -5px;
                        .pic {
                            img {
                                transform: scale(1.1);
                            }
                        }
                        .text {
                            font-weight: bold;
                            color: #61AE8F;
                        }
                    }
                }
                .placeholder {
                    @include res(width,510px,(lg:32.36%,xs:100%));
                }
            }
        }
    }
}